import React, { Component } from "react";
import Router from "./Router";
import { css } from "glamor";
import { createBrowserHistory } from "history";

import "assets/styles/typography.css";

const containerStyle = css({
  height: "100%",
  width: "100%",
  display: "flex",
  textAlign: "center",
  justifyContent: "space-between",
  position: "relative"
});

class App extends Component {
  constructor(props) {
    super(props);
    // Initialize history once to prevent mismatches
    this.history = createBrowserHistory();
  }

  render() {
    return (
      <div {...containerStyle}>
        <Router history={this.history} />
      </div>
    );
  }
}

export default App;