import React from "react";
import { css } from "glamor";
import { Helmet } from "react-helmet";
import Header from "components/shared/header/";

const containerStyle = css({
  width: "100%"
});

const contentStyle = css({
  height: "50%",
  paddingTop: "3em",
  width: "100%",
  display: "inline-block",
  backgroundColor: "#fefefe"
});

function NotFound() {
  return (
    <div {...containerStyle}>
      <Helmet>
        <title>404 - Page Not Found</title>
      </Helmet>
      <Header height={"50vh"}>
        <h1>404</h1>
        <h3>
          <a href="/">back to home</a>
        </h3>
      </Header>
      <div {...contentStyle} />
    </div>
  );
}

export default NotFound;
