import React, { useEffect, useRef, useState } from "react";
import { css } from "glamor";
import createShader from "gl-shader";
import createBuffer from "gl-buffer";
import flyCamera from "gl-flyCamera";
import { vec3 } from "gl-matrix";
import vertexShader from "assets/shaders/scene.vert";
import arrowsFragmentShader from "assets/shaders/arrows.frag";
import swirlFragmentShader from "assets/shaders/swirl.frag";
import betterShapeFragmentShader from "assets/shaders/betterShape.frag";
import shapeFragmentShader from "assets/shaders/shape.frag";
import tripFragmentShader from "assets/shaders/trip.frag";

const SHADER_OPTIONS = {
  arrows: arrowsFragmentShader,
  betterShape: betterShapeFragmentShader,
  shape: shapeFragmentShader,
  trip: tripFragmentShader,
  swirl: swirlFragmentShader,
};

function Header({ height, children, shaderName }) {
  const headerRef = useRef(null);
  const canvasRef = useRef(null);
  const [coverRevealed, setCoverRevealed] = useState(false);

  let fragmentShader;
  if (!shaderName) {
    const shaderKeys = Object.keys(SHADER_OPTIONS);
    const random = Math.floor(Math.random() * shaderKeys.length);
    fragmentShader = SHADER_OPTIONS[shaderKeys[random]];
  } else {
    fragmentShader = SHADER_OPTIONS[shaderName];
  }

  useEffect(() => {
    // Bypass if prerendering (ReactSnap)
    if (navigator.userAgent === "ReactSnap") return;

    const headerEl = headerRef.current;
    const canvas = canvasRef.current;
    const gl =
      canvas.getContext("webgl") ||
      canvas.getContext("webgl-experimental") ||
      canvas.getContext("experimental-webgl");
    if (!gl) {
      throw new Error("Unable to initialize gl");
    }

    // Setup fly camera controls
    const controls = new flyCamera({
      domElement: headerEl
    });
    controls.start();

    // Quality / timing variables
    let quality = 1,
      defaultQuality = 1,
      lastTimeStamp = 0,
      startTime = 0,
      ellapsedTime = 0;

    // Create full-screen vertex buffer (two triangles covering clip space)
    const buffer = createBuffer(
      gl,
      [
        // First triangle
        1.0, 1.0, -1.0, 1.0, -1.0, -1.0,
        // Second triangle
        -1.0, -1.0, 1.0, -1.0, 1.0, 1.0,
      ]
    );

    // Create shader from vertex and fragment shaders
    const shader = createShader(gl, vertexShader, fragmentShader);
    shader.attributes.position.location = 0;

    // Normalized mouse coordinates [0,1]
    let mouse = [0.5, 0.5];

    function updateMouse(event) {
      const rect = headerEl.getBoundingClientRect();
      const x = (event.clientX - rect.left) / rect.width;
      const y = 1 - (event.clientY - rect.top) / rect.height;
      mouse = [x, y];
    }
    headerEl.addEventListener("mousemove", updateMouse);

    // Use header dimensions for canvas size.
    function resizeCanvas() {
      const width = headerEl.clientWidth;
      const height = headerEl.clientHeight;
      canvas.width = width * quality;
      canvas.height = height * quality;
      gl.viewport(0, 0, canvas.width, canvas.height);
    }

    function resize() {
      quality = defaultQuality;
      const width = headerEl.clientWidth;
      const height = headerEl.clientHeight;
      if (canvas.width !== width * quality || canvas.height !== height * quality) {
        startTime = 0;
        resizeCanvas();
      }
    }

    function render(dt) {
      shader.bind();
      buffer.bind();
      shader.attributes.position.pointer();

      // Set uniforms
      shader.uniforms.uResolution = [canvas.width, canvas.height];
      shader.uniforms.uMouse = mouse;

      const vectorDir = vec3.fromValues( 0, 0, -1 );
      vec3.transformQuat( vectorDir, vectorDir, controls.quaternion );
      vec3.normalize( vectorDir, vectorDir );
      const vectorUp = vec3.fromValues( 0, 1, 0 );
      vec3.transformQuat( vectorUp, vectorUp, controls.quaternion );
      vec3.normalize( vectorUp, vectorUp );
      const vectorRight = vec3.fromValues( 1, 0, 0 );
      vec3.transformQuat( vectorRight, vectorRight, controls.quaternion );
      vec3.normalize( vectorRight, vectorRight );

      // const vectorDir = vec3.fromValues(0, 0, -1);
      // const vectorUp = vec3.fromValues(0, 1, 0);
      // const vectorRight = vec3.fromValues(1, 0, 0);

      shader.uniforms.uCamPosition = controls.position;
      shader.uniforms.uCamDir = vectorDir;
      shader.uniforms.uCamUp = vectorUp;
      shader.uniforms.uCamRight = vectorRight;

      // Global time uniforms
      const normalDt = ellapsedTime / 3000;
      shader.uniforms.uGlobalTime = normalDt;
      shader.uniforms.uSinGlobalTime = Math.sin(normalDt);
      shader.uniforms.uCosGlobalTime = Math.cos(normalDt);
      shader.uniforms.uFieldOfView = 0.4;

      gl.drawArrays(gl.TRIANGLES, 0, 6);
      controls.update(dt);
    }

    function loop(timeStamp) {
      if (!startTime) startTime = timeStamp;
      ellapsedTime = timeStamp - startTime;
      const dt = timeStamp - lastTimeStamp;
      lastTimeStamp = timeStamp;

      // Example quality adjustment early on
      if (ellapsedTime < 5000 && dt > 45.0) {
        quality -= 0.01;
        resizeCanvas();
      }

      render(dt);
      requestAnimationFrame(loop);
    }

    // Initial canvas setup and start render loop
    resizeCanvas();
    window.addEventListener("resize", resize);
    requestAnimationFrame(loop);

    // After shaders are loaded and rendering has begun, animate cover removal.
    const revealTimeout = setTimeout(() => {
      setCoverRevealed(true);
    }, 100); // adjust delay as needed

    return () => {
      clearTimeout(revealTimeout);
      window.removeEventListener("resize", resize);
      headerEl.removeEventListener("mousemove", updateMouse);
    };
  }, [fragmentShader]);

  // Main header styles
  const headerStyle = css({
    height: height,
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    paddingBottom: "3em",
    overflow: "hidden",
    textShadow:
      "-1px -1px 0 #cdcdcd, 1px -1px 0 #cdcdcd, -1px 1px 0 #cdcdcd, 1px 1px 0 #cdcdcd",
  });

  // The canvas container sits behind the header children.
  const canvasContainerStyle = css({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: -1,
  });

  // Canvas itself covers the container.
  const canvasStyle = css({
    width: "100%",
    height: "100%",
  });

  // Base style for the cover element. Note the transformOrigin set to bottom for a roll-up effect.
  const coverBaseStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#fff", // change as needed (color, gradient, etc.)
    transformOrigin: "top",
    transition: "transform 0.2s ease-in",
    pointerEvents: "none",
  };

  // The cover’s transform is determined by state.
  const coverDynamicStyle = {
    transform: coverRevealed ? "scaleY(0)" : "scaleY(1)",
  };

  return (
    <header {...headerStyle} ref={headerRef}>
      <div {...canvasContainerStyle}>
        <canvas {...canvasStyle} ref={canvasRef} />
        <div style={{ ...coverBaseStyle, ...coverDynamicStyle }} />
      </div>
      {children}
    </header>
  );
}

export default Header;